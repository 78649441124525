<template>
	<div>
		<form v-on:submit.prevent="save">
			<div class="row mb-3">
				<div class="col-12 col-md-6 col-lg-8">
					<h1>User</h1>
				</div>
				<div class="col-12 col-md-6 col-lg-4 text-right">
					<button type="button" class="btn btn-light ml-1" v-on:click="close()"><i class="fa-regular fa-times" aria-hidden="true"></i> Close</button>
					<button type="submit" class="btn btn-primary ml-1"><i class="fa-regular fa-save" aria-hidden="true"></i> Save</button>
				</div>
			</div>

			<div class="row">
				<div class="col-12 col-lg-6">
					<div class="card mb-3">
						<div class="card-header"><b>User Information</b></div>

						<div class="card-body">
							<div class="form-row mb-2">
								<div class="col-12 col-md-6 col-lg-4 col-xl-3">
									<label for="fname" class="required">First Name</label>
								</div>
								<div class="col-12 col-md-6 col-lg-8 col-xl-9">
									<input type="text" id="fname" v-model="edit_user.fname" class="form-control" required />
								</div>
							</div>
							<div class="form-row mb-2">
								<div class="col-12 col-md-6 col-lg-4 col-xl-3">
									<label for="lname" class="required">Last Name</label>
								</div>
								<div class="col-12 col-md-6 col-lg-8 col-xl-9">
									<input type="text" id="lname" v-model="edit_user.lname" class="form-control" required />
								</div>
							</div>
							<div class="form-row mb-2">
								<div class="col-12 col-md-6 col-lg-4 col-xl-3">
									<label for="email" class="required">Email</label>
								</div>
								<div class="col-12 col-md-6 col-lg-8 col-xl-9">
									<input type="email" id="email" v-model="edit_user.email" class="form-control" required />
								</div>
							</div>
							<div class="form-row mb-2">
								<div class="col-12 col-md-6 col-lg-4 col-xl-3">
									<label for="username" class="required">Username</label>
								</div>
								<div class="col-12 col-md-6 col-lg-8 col-xl-9">
									<input type="text" id="username" v-model="edit_user.username" class="form-control" required />
								</div>
							</div>
							<div class="form-row mb-2">
								<div class="col-12 col-md-6 col-lg-4 col-xl-3">
									<label for="password">Password</label>
								</div>
								<div class="col-12 col-md-6 col-lg-8 col-xl-9">
									<input type="password" id="password" v-model="edit_user.password" class="form-control" placeholder="Leave blank unless changing it" :required="edit_user.user_id == '' || edit_user.user_id == 0 || edit_user.user_id == null" />
								</div>
							</div>
							<div class="form-row">
								<div class="col-12 col-md-6 col-lg-4 col-xl-3">
									<label for="active">Active</label>
								</div>
								<div class="col-12 col-md-6 col-lg-8 col-xl-9">
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" id="active" :value="true" v-model="edit_user.active">
										<label class="custom-control-label" for="active"></label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-12 col-lg-6">
					<div class="card mb-3">
						<div class="card-header"><b>User Roles</b></div>

						<div class="card-body">
							<div v-for="role in roles" :key="role.acl_role_id">
								<div class="custom-control custom-checkbox">
									<input type="checkbox" class="custom-control-input" :id="'role_' + role.acl_role_id" v-model="edit_user.acl_role_ids" :value="role.acl_role_id" :disabled="!canSetRoles">
									<label class="custom-control-label" :for="'role_' + role.acl_role_id">{{role.role}}</label>
								</div>
							</div>
						</div>

						<div class="card-footer text-center" v-if="!canSetRoles"><i class="fa-regular fa-info-circle"></i> You can't change your own roles.</div>
					</div>
				</div>
			</div>
			<div class="row" v-if="canDelete">
				<div class="col-12 text-center">
					<button type="button" @click="remove" class="btn btn-danger btn-sm"><i class="fa-regular fa-trash-alt"></i> Disable User</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';

	export default {
		name: 'User',
		data() {
			return {
				required_permissions: [],
				edit_user: {user_id: null, active: 1, roles: [], acl_role_ids: []}
			}
		},
		mounted()
		{
			this.load();
		},
		computed:
		{
			canDelete()
			{
				return this.edit_user.user_id && this.edit_user.user_id != this.user.user_id && this.userHasAction(this.user, ['IDDQD', 'ADMIN']);
			},
			canSetRoles()
			{
				if(this.userHasAction(this.user, ['IDDQD']))
				{
					return true;
				}

				return this.edit_user.user_id != this.user.user_id && this.userHasAction(this.user, ['IDDQD', 'ADMIN']);
			},
			...mapGetters([
				"user",
				"users",
				"roles"
			])
		},
		methods:
		{
			load()
			{
				var user_id = this.$route.params.user_id;

				if(!user_id || user_id <= 0)
				{
					return;
				}

				this.$store.commit("startLoading");

				this.CORS('GET', "/users/" + user_id, null,
					(data) =>
					{
						this.edit_user = data;
						this.edit_user.active = Boolean(this.edit_user.active);
						this.$store.commit("stopLoading");
					},
					() =>
					{
						this.$store.commit("stopLoading");
						this.showError("Error", "Error loading user.", true, null);
					});
			},
			save()
			{
				var url = "";
				var method = "";
				this.$store.commit("startLoading");

				if(Number(this.edit_user.user_id) > 0)
				{
					url = "/users/" + this.edit_user.user_id;
					method = "PUT";
				}
				else
				{
					url = "/users";
					method = "POST";
				}

				this.CORS(method, url, JSON.stringify(this.edit_user),
					(data) =>
					{
						data.password = "";
						this.edit_user = data;
						this.edit_user.active = Boolean(this.edit_user.active);
						this.$store.commit("stopLoading");
						this.$router.push("/users").catch(() => { /* ignore error */ });
					},
					(response) =>
					{
						this.$store.commit("stopLoading");
						this.showError("Error", "Error saving user: " + response.responseText, true, null);
					});
			},
			close()
			{
				this.$router.push("/users");
			},
			remove()
			{
				this.nerivon_confirm("Are you sure?", "<p>This user will be <b>DELETED</b>.</p>", "warning", true, (c) =>
				{
					if(c)
					{
						this.$store.commit("startLoading");

						this.CORS('DELETE', "/users/" + this.edit_user.user_id, null,
							() =>
							{
								this.$store.commit("stopLoading");
								this.$router.push("/users").catch(() => { /* ignore error */ });
							},
							(response) =>
							{
								this.$store.commit("stopLoading");
								this.showError("Error", "Error deleting user: " + response.responseText, true, null);
							});
					}
				});
			}
		},
		watch: {
			'$route' (to, from)
			{
				if(to == from)
				{
					return;
				}

				this.load();
			}
		}
	}
</script>
